'use strict';
var $ = require('jquery');

var _SelectDigiplus = function(elementJquery){

  var ctSelect = $(elementJquery.find('.digiplus_select')[0]);
  var select = $(elementJquery.find('select')[0]);
  var options = elementJquery.find('select option');
  var label = $(elementJquery.find('label')[0]);
  var error = elementJquery.find('.formErrorMessage');
  var labelSelected = null;

  /**
    * init component
    * @returns {void}
    * @private
  */
  var init = function () {
    // set id on element parent
    if (!elementJquery.attr('id') && select.attr('name')) {
      elementJquery.attr('id', 'inputCT_' + select.attr('name'));
    }

    // add disabled attribut on first element if no value
    if (options && options.length > 0) {
      var firstOption = $(options[0]);

      if ((!firstOption.attr('value') || firstOption.attr('value') === '') && (!firstOption.attr('disabled'))) {
        firstOption.attr('disabled', true);
      }
    }

    if (error && error.length > 0) {
      elementJquery.addClass("error");
    }

    refreshLabelSelected();

    select.on("focusin", function(){
      elementJquery.addClass("focus");
    });

    select.on("focusout", function(){
      elementJquery.removeClass("focus");
      if (select.val() === '') {
        elementJquery.removeClass("notempty");
      }
    });

    select.on("change", function(){
      refreshLabelSelected();
    });

    select.on("click", function(){
      if (select.val() === '') {
        elementJquery.addClass("focus");
      }
    });
  };

  /**
    *
    * @private
    * @returns {void}
  */
  var refreshLabelSelected = function(){

    if (labelSelected === null) {
      var html = '<span class="labelSelected" aria-hidden="true"></span>';
      select.after(html);
      labelSelected = $(ctSelect.find('.labelSelected')[0]);
    }

    var disabled = false;
    var optionSelected = $(elementJquery.find('select option:selected')[0]);
    if (optionSelected.prop('disabled') !== true) {
      labelSelected.html($(elementJquery.find('select option:selected')[0]).text());
    } else {
      disabled = true;
      labelSelected.html('');
    }


    if (select.val() !== '' && disabled === false) {
      elementJquery.removeClass("focus");
      elementJquery.addClass("notempty");
    } else {
      elementJquery.removeClass("focus");
      elementJquery.removeClass("notempty");
    }

    var errorDiv = elementJquery.find('.formErrorMessage');
    errorDiv.remove();
    elementJquery.removeClass("error");

  };

  init();
};

function newInput(elementJquery){
  return new _SelectDigiplus(elementJquery);
}

module.exports = {
  newInput
};
