'use strict';


// vendor dependencies
var $ = require('jquery');
require('jquery-ui/tabs');

var config = require('./config');

var $loginTabs,
  $stepsTabs,
  stepNavigationIsActive = false;

$(document).ready(function() {
  var loginTabsOptions,
    stepsTabsOptions;

  // login tabs
  $loginTabs = $( ".js-login-tabs" );
  if ($loginTabs.length) {

    $loginTabs.tabs();
    $loginTabs.on("tabsactivate", function( event, ui ) {
        // we use pushState when available in order to avoid automatic scrolling to the anchor
        if ('pushState' in window.history) {
          // add anchor to url so that bookmarks will work
          // jquery-ui.tabs opens the correct tab automatically if a hash is present in the url
          window.history.pushState(null, null, ui.newTab.context.hash);
        }
    });

    $(window).on('hashchange', function () {
      if (!location.hash) {
        $('.js-login-tabs').tabs('option', 'active', 0); // activate first tab by default
        return;
      }
      $('.js-login-tabs > ul > li > a').each(function (index, a) {
        if ($(a).attr('href') === location.hash) {
          $('.js-login-tabs').tabs('option', 'active', index);
        }
      });
    });

  }

  // steps tabs
  $stepsTabs = $( ".js-steps-tabs" );
  if ($stepsTabs.length) {

    stepsTabsOptions = {
      beforeActivate: function (event, ui) {
        if (!stepNavigationIsActive) {
          event.preventDefault();
          return;
        }
      }
    };

    $stepsTabs.tabs(stepsTabsOptions);
  }

});

function tabChangeTo (newTabIndex) {
  if (!$loginTabs.length) {
    return;
  }
  $loginTabs.tabs('option', 'active', newTabIndex);
}

function stepChangeTo (newStepIndex) {
  if (!$stepsTabs.length) {
    return;
  }
  stepNavigationIsActive = true;
  $stepsTabs.tabs('option', 'active', newStepIndex);
  stepNavigationIsActive = false;
}

module.exports = {
  tabChangeTo,
  stepChangeTo
};
