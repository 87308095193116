'use strict';
var $ = require('jquery');


function displayModalDigiplus(id, pWidth) {

  if ($('#' + id).length) {
    $('body').addClass('digiplusModalBody');

    var wWidth = $(window).width();
    var wHeight = $(window).height();

    var minWPopin = 600;

    $('#' + id).dialog(
      {
        modal: true,
        draggable: false,
        minWidth: pWidth ? (wWidth >= minWPopin ? pWidth : wWidth) : (wWidth >= minWPopin ? minWPopin : wWidth),
        maxWidth: wWidth,
        minHeight: 0,
        show: {
          effect: "blind",
          duration: 800
        },
        close: function() {
          $('body').removeClass('digiplusModalBody');
        }
      });
  }

}
function displayModal(id) {
  $('#' + id).dialog({modal: true, draggable: false});
}

function close(id) {
  $('#' + id).dialog('close');
}

module.exports = {
  displayModal,
  displayModalDigiplus,
  close
};
