'use strict';
var $ = require('jquery');

var CoregisterCtrl = function(element) {


  var blockConventionRegister = element.find('#blockConvention_register');
  var buttonSubmitLogin = element.find('.formFooter_submit');
  var buttonSubmitLoginPlus = element.find('#bt_loginPlus_submit');
  var buttonSubmitRegister = element.find('.digiplusButton_submit');
  var inputLoginEmailCP = window.APP._getComponentFromID('inputCT_' + element.find('#login_plus_login').attr('name'));
  var inputRegisterEmail = element.find('#user_ccu_primaryEmail');
  var inputConventionLoginCP = window.APP._getComponentFromID('blockConvention_login');
  var inputConventionLoginPlusCP = window.APP._getComponentFromID('blockConvention_loginPlus');
  var blockConventionCommonCP = null;
  var tabButtonRegister = element.find('#tabBtRegister');;
  var tabButtonLogin = element.find('#tabBtLogin');;
  var tabs = element;

  /**
    * init component
    * @returns {void}
    * @private
  */
  var init = function () {


    // check if forms login or register has errors
    var testErrors = element.find('#login').find('.formErrorMessage');
    if (testErrors.length) {
      // if has errors display tab login
      document.location.href = '#login';
    }
    testErrors = element.find('#registration').find('.formErrorMessage');
    if (testErrors.length && (!testErrors.eq(0).hasClass('errorAjaxCcuEmailExists'))) {
      // if has errors display tab register
      document.location.href = '#registration';
    }

    tabButtonRegister.on('click', function() {
      resize();
    });

    tabButtonLogin.on('click', function() {
      resize();
    });

    buttonSubmitLogin.on('click', function() {
      if (blockConventionCommonCP) {
        setTimeout(function(){
          checkErrorOnConvention();
        }, 200);
      }
    });

    buttonSubmitLoginPlus.on('click', function() {
      if (blockConventionCommonCP) {
        setTimeout(function(){
          checkErrorOnConvention(true);
        }, 200);
      }
    });

    buttonSubmitRegister.on('click', function() {
      setTimeout(function(){
        resize();
      }, 200);
    });

    // set email login when email register change
    inputLoginEmailCP.setValue(inputRegisterEmail.val());
    inputRegisterEmail.on('change', function() {
      inputLoginEmailCP.setValue(inputRegisterEmail.val());
    });

    if (inputConventionLoginPlusCP) {
      blockConventionCommonCP = inputConventionLoginPlusCP;
      if (inputConventionLoginCP) {
        inputConventionLoginCP.element.hide();
        inputConventionLoginPlusCP.onChange = function() {
          inputConventionLoginCP.setValue(inputConventionLoginPlusCP.checked);
        };
      }
    }else if (inputConventionLoginCP) {
      blockConventionCommonCP = inputConventionLoginCP;
      if (inputConventionLoginPlusCP) {
        inputConventionLoginPlusCP.element.hide();
        inputConventionLoginCP.onChange = function() {
          inputConventionLoginPlusCP.setValue(inputConventionLoginCP.checked);
        };
      }
    }

    if (blockConventionRegister.length || blockConventionCommonCP) {
      resize();
      $(window).on('resize', resize);
    } else {
      tabs.css('padding-top', '0px');
    }
  };

  /**
    *
    * @param {boolean} fromLoginPlus indicate if convention is from login
    * @returns {void}
    * @private
  */
  var checkErrorOnConvention = function(fromLoginPlus) {

    var error;

    if (fromLoginPlus) {
      if (inputConventionLoginPlusCP && !inputConventionLoginPlusCP.checked) {
        error = inputConventionLoginPlusCP.element.find('.formErrorMessage');
        if (error.length > 1) {
          error = error.eq(1);
        }
        blockConventionCommonCP.element.find('.formErrorBox_message').find('.formErrorMessage').remove();
        blockConventionCommonCP.element.find('.formErrorBox_message').append(error);
        blockConventionCommonCP.element.addClass("error");
      }
    }else {
      if (inputConventionLoginCP && !inputConventionLoginCP.checked) {
        error = inputConventionLoginCP.element.find('.formErrorMessage');
        if (error.length > 1) {
          error = error.eq(1);
        }
        blockConventionCommonCP.element.find('.formErrorBox_message').find('.formErrorMessage').remove();
        blockConventionCommonCP.element.find('.formErrorBox_message').append(error);
        blockConventionCommonCP.element.addClass("error");
        $(document).scrollTop(blockConventionCommonCP.element.offset().top);
      }
    }

    resize();
  };

  /**
    * @param {Object} evt event resize
    * @returns {void}
    * @private
  */
  var resize = function(evt) {

    var h;

    // calcul padding tabs
    if (blockConventionRegister.length && blockConventionRegister.height() > 0) {

      h = blockConventionRegister.height();
      tabs.css('padding-top', (h + 35) + 'px');

    }else if (blockConventionCommonCP && blockConventionCommonCP.element.height() > 0) {

      h = blockConventionCommonCP.element.height();
      tabs.css('padding-top', (h + 35) + 'px');
    }else {
      tabs.css('padding-top', '35px');
    }


  };

  init();
};


function newInstance(elementJquery){
  return new CoregisterCtrl(elementJquery);
}

module.exports = {
  newInstance
};
