'use strict';
var bowser = require('bowser');

var platformUtils = {
  isMobile: function(){
    return (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
  },
  isIOS: function(){
    return (/iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()));
  },
  isAndroid: function(){
    return (/android/i.test(navigator.userAgent.toLowerCase()));
  },
  isChrome: function(){
    return bowser.name === "Chrome";
  },
  getBrowserVersion: function(){
    return Number(bowser.version);
  },
  getIOSVersion: function(){
    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
};

module.exports = platformUtils;
