'use strict';

// add browser detect case.

// vendor dependencies

var $ = require('jquery');
var dialog = require('jquery-ui/dialog');
var bowser = require('bowser');

$(document).ready(function () {

  // desactivate popin
  return;


  var showDetectBrowserPopin = true;
  if (bowser.name === "Internet Explorer" && Number(bowser.version) >= 9) {
    showDetectBrowserPopin = false;
  }
  if (bowser.name === "Opera" && Number(bowser.version) >= 11) {
    showDetectBrowserPopin = false;
  }
  if (bowser.name === "Chrome" && Number(bowser.version) >= 48) {
    showDetectBrowserPopin = false;
  }
  if (bowser.name === "Firefox" && Number(bowser.version) >= 49) {
    showDetectBrowserPopin = false;
  }
  if (bowser.name === "Safari" && Number(bowser.version) >= 5) {
    showDetectBrowserPopin = false;
  }
  if (bowser.ios && bowser.webkit && Number(bowser.version) >= 5) {
    showDetectBrowserPopin = false;
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    showDetectBrowserPopin = false;
  }

  if (showDetectBrowserPopin) {
    $( ".detectBrowser_popin" ).addClass('_is_displayed');
    window.modal.displayModalDigiplus('detectBrowserPopin', 6 / 8 * $(window).width());
    //console.log('vieux navigateur');
  }

// add feature detect case.
  var showFeatureBowserPopin = true;
  // TODO problems window.Modernizr.placeholder and window.Modernizr.draganddrop returns undefined
  //if (window.Modernizr.flexbox && window.Modernizr.placeholder && window.Modernizr.inlinesvg && window.Modernizr.draganddrop) {
    //showFeatureBowserPopin = false;
  //}
  if (window.Modernizr.flexbox && window.Modernizr.inlinesvg) {
    showFeatureBowserPopin = false;
  }

  if (!showDetectBrowserPopin && showFeatureBowserPopin && (bowser.name !== "Internet Explorer" ||
  ( bowser.name === "Internet Explorer" && Number(bowser.version) < 9))) {

    $( ".detectFeature_popin" ).addClass('_is_displayed');
    window.modal.displayModalDigiplus('detectBrowserPopin', 6 / 8 * $(window).width());
  }

});
