'use strict';
var $ = require('jquery');

var BubbleInfoCtrl = function($body, $parent){

  var $oldContainer = $parent.find(".js-bubbleInfo-container");
  var $content = $oldContainer.children();
  var $icon = $(document.createElement('span'));
  var $arrow = $(document.createElement('span'));
  var $container = $(document.createElement('div'));
  var datas = {
    'margin': $parent.attr('data-margin'),
    'arrowDirection': $parent.attr('data-arrow-direction')};

  /**
    * init component
    * @returns {void}
    * @private
  */
  var init = function () {
    createBubbleInfo();

    $parent.hover(function() {
      $container.removeClass('hidden');
      showBubbleInfo();
    });

    $parent.mouseleave(function () {
      $container.addClass('hidden');
    });

    $parent.on("click", function() {
      if ($container.hasClass('hidden')) {
        var $bubbles = $body.find('.bubbleInfo_container');
        for (var i = 0 ; i < $bubbles.length ; i++) {
          $bubbles.addClass('hidden');
        }
      }
      $container.toggleClass('hidden');
      showBubbleInfo();
    });
  };

  /**
    * @returns {void}
    * @private
  */
  var createBubbleInfo = function () {
    $container.addClass('bubbleInfo_container');
    $container.addClass('hidden');
    $container.css('pointer-events', 'none');
    $arrow.addClass('bubbleInfo_arrow js-bubbleInfo-arrow');
    $icon.addClass('bubbleInfo-icon js-bubbleInfo-icon');

    $parent.append($icon);

    $container.append($oldContainer.children());
    $container.append($arrow);
    $body.append($container);

    $oldContainer.remove();
  };

  /**
    * @returns {void}
    * @private
  */
  var setContainerPosition = function () {
    var arrowDirection = 'up';
    var containerPosition = [ $container.offset().left, $container.offset().top];
    var viewportHeight = $(window).scrollTop() + $(window).height();
    var containerHeight = $container.children().height() + parseInt($container.children().css('padding-top')) * 2;

    containerPosition[0] = $icon.offset().left + ($icon.width() - $container.width()) / 2;
    containerPosition[1] += datas.margin * 2 + $icon.width();

    var containerBottom = containerPosition[1] + containerHeight;
    var containerTop = containerPosition[1] - $icon.height() - containerHeight;

    if (viewportHeight < (containerBottom + 10) || datas.arrowDirection === 'down') {
      arrowDirection = 'down';
      containerPosition[1] -= 40;
    }

    if ($(window).scrollTop() > (containerTop - 10) && datas.arrowDirection === 'down') {
      arrowDirection = 'up';
      containerPosition[1] += 40;
    }

    $container.css('left', containerPosition[0]);
    $container.css('top', containerPosition[1]);

    return arrowDirection;
  };

  /**
    *
    * @param {string} arrowDirection direction of the arrow
    * @returns {void}
    * @private
  */
  var setContentPosition = function (arrowDirection) {
    var contentLeft = 0;
    var contentPadding = 30;
    var windowMargin = 30;
    var windowWidth = $(window).width();

    if (windowWidth <= 540 ) {
      $content.css('width', $(window).width() - windowMargin * 2);
    }

    var rightContentPosition = $icon.offset().left + $icon.width() / 2 + $content.width() / 2 + contentPadding;
    var leftContentPosition = $icon.offset().left + $icon.width() / 2 - $content.width() / 2 - contentPadding;

    if (windowWidth < rightContentPosition) {
      contentLeft = $(window).width() - rightContentPosition - windowMargin;
    }

    if (leftContentPosition < 0) {
      contentLeft = -1 * leftContentPosition + windowMargin;
    }

    $content.css('left', contentLeft);

    if (arrowDirection === 'down') {
      $content.css('bottom', (parseInt($arrow.css('height')) * 2) + 'px');
    }
    else {
      $content.css('bottom', '');
    }
  };

  /**
    *
    * @returns {void}
    * @private
  */
  var showBubbleInfo = function () {
    $container.css('left', $parent.offset().left);
    $container.css('top', $parent.offset().top);

    var arrowDirection = setContainerPosition();

    if (arrowDirection === 'down') {
      $arrow.addClass('bubbleInfo_arrow-down');
    }
    else {
      $arrow.removeClass('bubbleInfo_arrow-down');
    }

    setContentPosition(arrowDirection);
  };

  init();
};

var $body = $('body');

function newInstance(elementJquery){
  return new BubbleInfoCtrl($body, elementJquery);
}

module.exports = {
  newInstance
};
