'use strict';
var platformUtils = require('../../helpers/platformUtils');
var $ = require('jquery');

/*
-------- Controller .js-registerConfirmCtlr ---------
*/
var RegisterConfirmCtrl = function(elementJquery){

  //------------------------------------------
  // if is on Mobile, redirect to the app or the store
  //------------------------------------------

  if (platformUtils.isMobile()) {


    // props android and ios for old process openning app
    var urlAppId = elementJquery.attr('data-appId');

    // props android for intent opening app
    var androidPackage = elementJquery.attr('data-android-package');
    var androidShemeId = elementJquery.attr('data-android-scheme');

    // props ios for link universal opening app
    var iosLinkStore = elementJquery.attr('data-ios-linkStore');
    var pathIOSApp = elementJquery.attr('data-ios-universalLink-path');

    var paramApp = elementJquery.attr('data-appParam');


    var start = Date.now();
    var now = 0;

    var store_or_close = function(){

      now = Date.now();

      if (now - start > 3000) {

      } else {

        if (platformUtils.isIOS())
        {
          butConnect.attr('href', iosLinkStore);
          window.location.replace(iosLinkStore);

        } else if (platformUtils.isAndroid())
        {
          butConnect.attr('href', 'http://play.google.com/store/apps/details?id=' + androidPackage);
          window.location.replace('http://play.google.com/store/apps/details?id=' + androidPackage);
        }
      }
    };


    var butConnect = $(elementJquery.find('.digiplusButton_submit')[0]);

    if (platformUtils.isAndroid() && platformUtils.isChrome() && platformUtils.getBrowserVersion() >= 25) {

      butConnect.attr('href', 'intent://' + paramApp + '/#Intent;scheme=' + androidShemeId + ';package=' + androidPackage + ';end');

    /*}else if(platformUtils.isIOS() && platformUtils.getIOSVersion()[0] >= 9){

      butConnect.attr('href', '/' + pathIOSApp);*/

    } else {
      butConnect.attr('href', urlAppId + '://' + paramApp);

      var iframe = document.createElement('iframe');
      iframe.width = 0;
      iframe.height = 0;
      iframe.style.display = 'none';

      setTimeout(store_or_close, 2000);

      iframe.src = urlAppId + '://' + paramApp;
      elementJquery.append($(iframe));

    }
  }
};

function newInstance(elementJquery){
  return new RegisterConfirmCtrl(elementJquery);
}

module.exports = {
  newInstance
};
