'use strict';

var $ = require('jquery');
var slick = require('slick-carousel');

var arrowPrevious = $('<button type="button" data-role="none" class="slick-prev slick-arrow slick-disabled" ' +
  'aria-label="Previous" role="button" aria-disabled="true" style="display: block;">Previous</button>');

var arrowNext = $('<button type="button" data-role="none" class="slick-next slick-arrow slick-disabled" ' +
  'aria-label="Next" role="button" aria-disabled="true" style="display: block;">Next</button>');

var Caroussel = function(elem) {

  /**
    * init component
    * @returns {void}
    * @private
  */
  var init = function () {
    elem.slick({
      dots: false,
      infinite: false,
      centerMode: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 540,
          settings: 'unslick'
        }
      ]
    });

    $(window).resize(function() {
      resize();
    });

    $(window).on('orientationchange', function() {
      resize();
    });

    resize();

  };

  var resize = function() {
    elem.slick('resize');

    if ($(window).width() > 540 && elem.find('.js-carousel-account').length < 4
      && elem.find('.js-carousel-account').length > 0) {

      elem.parent().prepend(arrowPrevious);
      elem.parent().append(arrowNext);
    }
    else if ($(window).width() < 540) {
      arrowPrevious.remove();
      arrowNext.remove();
    }
  };

  init();

};

function newInstance(elementJquery){
  return new Caroussel(elementJquery);
}

module.exports = {
  newInstance
};
