'use strict';

// vendor dependencies
var $ = require('jquery');
var validationConstraints = require('./validation-custom-constraints');

var DigiplusFormValidation = function(elementJquery){

  var form;
  var emailPattern = /^\w+([\.-]*\w+)*@\w+([\.-]*\w+)*(\.\w{2,})+$/;

  if (elementJquery.attr('data-formId-validation')) {
    form = elementJquery.find('#' + elementJquery.attr('data-formId-validation'));
  } else {
    form = elementJquery;
  }
  var copyPastDisable = elementJquery.find('.js-disableCopyPast');
  var inputsCt = elementJquery.find('.js-digiplusInput');
  var butSubmit = elementJquery.find('.digiplusButton_submit');
  var blockGlobalError = $('.formErrorBox');

  var init = function(){

    // check on submit
    butSubmit.on('click', function(e){
      e.preventDefault();

      if (checkForm()) {
        form.submit();
      }
    });


    // check on change
    var elementCt;
    var elementInput;
    var jsChecks;
    var i, j;
      for (i = 0; i < copyPastDisable.length; i++) {
          elementCt = $(copyPastDisable[i]);
          elementInput = elementCt.find('input');
          elementInput.bind("cut copy paste", function (e) {
              e.preventDefault();
          });
      }

    for (i = 0; i < inputsCt.length; i++) {
      elementCt = $(inputsCt[i]);
      elementInput = elementCt.find('input');

      if (!elementInput.length || elementInput.length === 0) {
        elementInput = elementCt.find('textarea');
        if (!elementInput.length || elementInput.length === 0) {
          elementInput = elementCt.find('select');
        }
      }

      if (elementInput.length && elementInput.length > 0) {


        if (elementInput.attr('data-js-check') && elementInput.attr('data-js-check') !== '') {
          jsChecks = elementInput.attr('data-js-check').split(' ');

          for (j = 0; j < jsChecks.length; j++) {

            switch (jsChecks[j]) {
              case 'ajaxEmailExists':
                var elmTemp1 = elementInput;
                elementInput.blur(function() {
                  if (elmTemp1.val()!= '' && emailPattern.test(elmTemp1.val())) {
                    checkAjaxEmailExists(elmTemp1);
                  }
                });
                break;
              case 'ajaxCcuEmailExists':
                var elmTemp2 = elementInput;
                elementInput.blur(function() {
                  if (elmTemp2.val()!= '' && emailPattern.test(elmTemp2.val())) {
                    checkAjaxCcuEmailExists(elmTemp2);
                  }
                });
                break;
            }

          }
        }
      }
    }
  };

  var checkForm = function(){
    var check = true;

    //console.log("checkForm ");
    //console.log("inputs " + inputs);
    //console.log("inputs.length " + inputs.length);


    if (check) {
      var elementCt;
      var elementInput;
      var jsChecks;
      var passElement;
      var currentCheck;
      var i, j;

      var scrollToErrorActive = true;

      for (i = 0; i < inputsCt.length; i++) {
        elementCt = $(inputsCt[i]);
        elementInput = elementCt.find('input');

        if (!elementInput.length || elementInput.length === 0) {
          elementInput = elementCt.find('textarea');
          if (!elementInput.length || elementInput.length === 0) {
            elementInput = elementCt.find('select');
          }
        }

        if (elementInput.length && elementInput.length > 0) {

          currentCheck = true;

          if (elementInput.attr('data-js-check') && elementInput.attr('data-js-check') !== '') {
            jsChecks = elementInput.attr('data-js-check').split(' ');

            //console.log("jsChecks " + jsChecks);

            for (j = 0; j < jsChecks.length; j++) {
              switch (jsChecks[j]) {
                case 'email':
                  currentCheck = currentCheck && checkEmail(elementInput);
                  check = check && currentCheck;
                  break;
                case 'pass':
                  currentCheck = currentCheck && checkPass(elementInput);
                  passElement = elementInput;
                  check = check && currentCheck;
                  break;
                case 'passConfirm':
                  currentCheck = currentCheck && checkSamePass(elementInput, passElement);
                  check = check && currentCheck;
                  break;
                case 'more64Chars':
                  currentCheck = currentCheck && check64Chars(elementInput);
                  check = check && currentCheck;
                  break;
                case 'specialChars':
                  currentCheck = currentCheck && checkNoSpecialChars(elementInput);
                  check = check && currentCheck;
                  break;
                case 'convention':
                  currentCheck = currentCheck && checkConvention(elementInput);
                  check = check && currentCheck;
                  break;
              }
            }
          }

          if (elementInput.attr('required') || elementInput.attr('required') === 'true' || elementInput.attr('required') === 'required') {
            // check required
            currentCheck = currentCheck && checkRequired(elementInput);
            check = check && currentCheck;
          }

          if (!currentCheck) {
            elementCt.addClass('error');
            if (scrollToErrorActive) {
              scrollToErrorActive = false;

              // check if popins are displayed. If the case, don't scroll
              var popins = $('.ui-dialog');
              var flag = false;
              var k, nb;
              if (popins.length && popins.length > 0) {
                nb = popins.length;
                for (k = 0; k < nb; k++) {
                  if (popins.eq(k).css('display') !== 'none') {
                    flag = true;
                    break;
                  }
                }
              }

              if (!flag) {
                if (blockGlobalError.length) {
                  blockGlobalError.addClass('display');
                  $('html, body').animate({scrollTop: 0}, 400);
                } else {
                  $('html, body').animate({scrollTop: elementCt.offset().top}, 400);
                }
              }
            }
          } else {
            elementCt.removeClass('error');
          }
        }
      }
    }

    //console.log("checkForm " + check);


    if (check) {
      if (blockGlobalError.length) {
        blockGlobalError.removeClass('display');
      }
    }

    return check;
  };




  var checkRequired = function(element){
    var flag = true;
    var ctErrors;

    var idErrorsCt = element.attr('data-js-error-container');
    if (idErrorsCt && idErrorsCt !== '') {
      ctErrors = $('#' + idErrorsCt);
    } else {
      ctErrors = element.parent();
    }

    if ((element.attr('type') === 'checkbox' && !element.is(':checked'))
      || (element.attr('type') === 'radio' && $('input[type=radio][name="' + element.attr('name') + '"]:checked').length !== 1)
      || (element.attr('type') !== 'checkbox' && element.attr('type') !== 'radio' && (!element.val() || element.val() === ''))) {


      var idError;
      if (element.attr('data-js-error-id')) {
        idError = element.attr('data-js-error-id');
      } else {
        idError = 'required_error_text';
      }
      if (!ctErrors.find(".errorRequired").length) {
        var html = '<p class="formErrorMessage errorRequired">'
          + $("#" + idError).text() + '</p>';
        ctErrors.append(html);
      }

      flag = false;
    } else {
      ctErrors.find(".errorRequired").remove();
    }

    return flag;
  };

  var check64Chars = function(element){
    var flag = true;
    var ctErrors;

    var idErrorsCt = element.attr('data-js-error-container');
    if (idErrorsCt && idErrorsCt !== '') {
      ctErrors = $('#' + idErrorsCt);
    } else {
      ctErrors = element.parent();
    }

    if (element.val() === '') {
      ctErrors.find(".errorNbChars").remove();
      return true;
    }

    if (!validationConstraints.checkNbCharsValue(element.val())) {

      if (!ctErrors.find(".errorNbChars").length) {
        var html = '<p class="formErrorMessage errorNbChars">'
          + $("#more64chars_error_text").text() + '</p>';
        ctErrors.append(html);
      }

      flag = false;
    } else {
      ctErrors.find(".errorNbChars").remove();
    }
    ctErrors.find(".errorRequired").remove();

    return flag;
  };

  var checkNoSpecialChars = function(element){
    var flag = true;
    var ctErrors;

    var idErrorsCt = element.attr('data-js-error-container');
    if (idErrorsCt && idErrorsCt !== '') {
      ctErrors = $('#' + idErrorsCt);
    } else {
      ctErrors = element.parent();
    }

    if (element.val() === '') {
      ctErrors.find(".errorSpecialChars").remove();
      return true;
    }

    if (!validationConstraints.checkNoSpecialCharsValue(element.val())) {

      if (!ctErrors.find(".errorSpecialChars").length) {
        var html = '<p class="formErrorMessage errorSpecialChars">'
          + $("#specialChars_error_text").text() + '</p>';
        ctErrors.append(html);
      }

      flag = false;
    } else {
      ctErrors.find(".errorSpecialChars").remove();
    }
    ctErrors.find(".errorRequired").remove();

    return flag;
  };

  var checkEmail = function(element){
    var flag = true;
    var ctErrors;

    var idErrorsCt = element.attr('data-js-error-container');
    if (idErrorsCt && idErrorsCt !== '') {
      ctErrors = $('#' + idErrorsCt);
    } else {
      ctErrors = element.parent();
    }

    if (element.val() === '') {
      ctErrors.find(".errorEmail").remove();
      return true;
    }


    if (!validationConstraints.checkEmail(element.val())) {

      if (!ctErrors.find(".errorEmail").length) {
        var html = '<p class="formErrorMessage errorEmail">'
          + $("#email_error_text").text() + '</p>';
        ctErrors.append(html);
      }

      flag = false;
    } else {
      ctErrors.find(".errorEmail").remove();
    }
    ctErrors.find(".errorRequired").remove();

    return flag;
  };

  var checkPass = function(element){
    var flag = true;
    var ctErrors;

    var idErrorsCt = element.attr('data-js-error-container');
    if (idErrorsCt && idErrorsCt !== '') {
      ctErrors = $('#' + idErrorsCt);
    } else {
      ctErrors = element.parent();
    }

    if (element.val() === '') {
      ctErrors.find(".errorPass").remove();
      return true;
    }

    var result = validationConstraints.checkPassValue(element.val());

    if (result[0] && result[1] && result[2] && result[3]  && result[4]  && result[5] ) {

      ctErrors.find(".errorPass").remove();

    } else {
      if (!ctErrors.find(".errorPass").length) {
        var html = '<p class="formErrorMessage errorPass">'
          + $("#pass_error_text").text() + '</p>';
        ctErrors.append(html);
      }
      flag = false;
    }
    ctErrors.find(".errorRequired").remove();

    return flag;
  };

  var checkSamePass = function(element1, element2){
    var flag = true;
    var ctErrors;


    var idErrorsCt = element1.attr('data-js-error-container');
    if (idErrorsCt && idErrorsCt !== '') {
      ctErrors = $('#' + idErrorsCt);
    } else {
      ctErrors = element1.parent();
    }

    if (element2.val() === '') {
      ctErrors.find(".errorSamePass").remove();
      return true;
    }


    if (element1.val() !== element2.val()) {

      if (!ctErrors.find(".errorSamePass").length) {
        var html = '<p class="formErrorMessage errorSamePass">'
          + $("#passConfirm_error_text").text() + '</p>';
        ctErrors.append(html);
      }

      flag = false;
    } else {
      ctErrors.find(".errorSamePass").remove();
    }
    ctErrors.find(".errorRequired").remove();

    return flag;
  };

  var checkAjaxEmailExists = function(element){

    var ctErrors;

    var idErrorsCt = element.attr('data-js-error-container');
    if (idErrorsCt && idErrorsCt !== '') {
      ctErrors = $('#' + idErrorsCt);
    } else {
      ctErrors = element.parent();
    }

    validationConstraints.ajaxEmailExists(element.val(), function() {
      if (!ctErrors.find(".errorAjaxEmailExists").length) {
        var html = '<p class="formErrorMessage digiplusInfoBubbleError errorAjaxEmailExists">'
          + $("#email_duplicate_error").text().replace('%email%', element.val()) + '</p>';
        ctErrors.append(html);
      }
    }, function(){
      ctErrors.find(".errorAjaxEmailExists").remove();
    });
  };

  var checkAjaxCcuEmailExists = function(element){
    var ctErrors;

    var idErrorsCt = element.attr('data-js-error-container');
    if (idErrorsCt && idErrorsCt !== '') {
      ctErrors = $('#' + idErrorsCt);
    } else {
      ctErrors = element.parent();
    }

    validationConstraints.ajaxCcuEmailExists(element.val(), function() {
      if (!ctErrors.find(".errorAjaxCcuEmailExists").length) {
        var html = '<p class="formErrorMessage digiplusInfoBubbleError errorAjaxCcuEmailExists">'
          + $("#email_duplicate_error").text().replace(/%email%/g, element.val()) + '</p>';
        ctErrors.append(html);
      } else {
        ctErrors.find(".errorAjaxCcuEmailExists").html($("#email_duplicate_error").text().replace(/%email%/g, element.val()));
      }
    }, function(){
      ctErrors.find(".errorAjaxCcuEmailExists").remove();
    });
  };

  var checkConvention = function(element){
    var flag = true;
    var ctErrors;

    var idErrorsCt = element.attr('data-js-error-container');
    if (idErrorsCt && idErrorsCt !== '') {
      ctErrors = $('#' + idErrorsCt);
    } else {
      ctErrors = element.parent();
    }

    if (element.attr('type') === 'checkbox' && !element.is(':checked')) {


      if (!ctErrors.find(".errorConvention").length) {
        var html = '<p class="formErrorMessage errorConvention">'
          + $("#convention_error_text").text() + '</p>';
        ctErrors.append(html);
      }

      flag = false;
    } else {
      ctErrors.find(".errorConvention").remove();
    }

    return flag;
  };


  init();

};

function newInstance(elementJquery){
  return new DigiplusFormValidation(elementJquery);
}

module.exports = {
  newInstance
};
