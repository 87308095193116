'use strict';
var $ = require('jquery');
var validationConstraints = require('../../validation/validation-custom-constraints');


var _InputPasswordDigiplus = function(elementJquery){

  var input = $(elementJquery.find('input')[0]);
  var divCheck = $(elementJquery.find('.js-passCheck')[0]);

  /**
    * init component
    * @returns {void}
    * @private
  */
  var init = function () {
    // set id on element parent
    if (!elementJquery.attr('id') && input.attr('name')) {
      elementJquery.attr('id', 'inputCT_' + input.attr('name'));
    }

    // case password with check
    if (input.attr('data-js-check').indexOf('passConfirm') === -1) {


      var check1 = $(divCheck.find('div')[0]);
      var check2 = $(divCheck.find('div')[1]);
      var check3 = $(divCheck.find('div')[2]);
      var check_digit = $(divCheck.find('div.check-digit'));
      var check_lower = $(divCheck.find('div.check-lowercase'));
      var check_upper = $(divCheck.find('div.check-uppercase'));
      input.parent().find('.show-password').on("mousedown", function () {
        input.attr('type', 'text');
      }).on("mouseup", function () {
        input.attr('type', 'password');
      });
      input.on("keyup", function(){

        var value = input.val();

        var result = validationConstraints.checkPassValue(value);

        // check 8 char
        check1.toggleClass('checked', result[0]);

        // check 1 letter
        check2.toggleClass('checked', result[1]);

        // check 1 number
        check3.toggleClass('checked', result[2]);
        
        // check 1 number
        check_digit.toggleClass('checked', result[2]);
        
        // check 1 number
        check_lower.toggleClass('checked', result[4]);
        
        // check 1 number
        check_upper.toggleClass('checked', result[5]);
      });
    } else {


      var checkPass2 = $(divCheck.find('div')[0]);
      var inputPass1 = $("input[data-js-check='pass']");

      inputPass1.on("keyup", function() {
        checkFunc(inputPass1, checkPass2);
      });

      input.on("keyup", function() {
        checkFunc(inputPass1, checkPass2);
      });
    }
  };

  /**
    *
    * @param {Object} inputPass1 dom input pass
    * @param {Object} checkPass2 dom input pass confirm
    * @returns {void}
    * @private
  */
  var checkFunc = function(inputPass1, checkPass2){
    if (inputPass1.val() !== '' && input.val() === inputPass1.val()) {
      checkPass2.toggleClass('checked', true);
    } else {
      checkPass2.toggleClass('checked', false);
    }
  };

  init();
};


function newInput(elementJquery){
  return new _InputPasswordDigiplus(elementJquery);
}

module.exports = {
  newInput
};
