'use strict';

var data = {};

if (window.EKS && window.EKS.DGP && window.EKS.DGP.data) {
  Object.assign(data, window.EKS.DGP.data);
}


module.exports = data;
