'use strict';
var $ = require('jquery');

var inputText = require('./form/inputText');
var inputSelect = require('./form/inputSelect');
var inputRadioCheck = require('./form/inputRadioCheck');
var inputPassword = require('./form/inputPassword');


var instance = function(elem) {


  var retInstance = null;

  if (elem.find('select').length > 0) {
    retInstance = inputSelect.newInput(elem);

  }else if (elem.find('input').length > 0) {

    var type = $(elem.find('input')[0]).attr('type');

    // generic js
    switch (type) {
      case 'text':
      case 'email':
      case 'password':
        retInstance = inputText.newInput(elem);
        break;
      case 'checkbox':
      case 'radio':
        retInstance = inputRadioCheck.newInput(elem);
        break;
    }

    // custom js
    switch (type) {
      case 'password':
        if (elem.find('.js-passCheck').length > 0) {
          inputPassword.newInput(elem);
        }
        break;
    }
  }

  return retInstance;



};

function newInstance(elementJquery){
  return new instance(elementJquery);
}

module.exports = {
  newInstance
};
