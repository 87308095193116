'use strict';

// vendor dependencies
var $ = require('jquery');

$(document).ready(function () {
  var $corporateBlockDisplayer = $(".userTypeRadio_radioBtn[value='COMPANY']").parent();
  var $corporateBlockHider = $(".userTypeRadio_radioBtn[value='PERSON']").parent();
  var $corporateBlock = $(".corporateBlock");

  // we need the hidden inputs to be disabled so that they don't interfere when submitting
  $corporateBlock.find('input').prop('disabled', true);

  $corporateBlockDisplayer.on("click", function (e) {
    $corporateBlock.find('input').prop('disabled', false);
    $corporateBlock.addClass('_is_opened');
  });
  $corporateBlockHider.on("click", function (e) {
    $corporateBlock.find('input').prop('disabled', true);
    $corporateBlock.removeClass('_is_opened');
  });

  if ($(".userTypeRadio_radioBtn[value='COMPANY'][readonly]").attr('checked') === 'checked') {
    $corporateBlock.addClass('_is_opened');
  }

  //hide the radial button switcher where there is no corporate block
  $(".userTypeRadio_radioBtn[readonly='readonly']").parents('.inputWrapper--radio').remove();
});
