'use strict';

let xitiParams = {
  globalParam: {
    x1: '',
    x2: '',
    x3: '',
    x4: '',
    x5: '',
    x6: '',
    x7: '',
    x8: '',
    x9: '',
    x10: '',
    x11: '',
    x12: ''
  },
  userParam: {
    xt_an: '',
    xt_ac: ''
  }
}, init = true;

function getReferrerOnInit() {
  if (init) {
    init = false;
    return '&ref=' + document.referrer;
  } else {
    return '';
  }
}

function getGlobalParam() {
  return '&x1=' + xitiParams.globalParam.x1 +
    '&x2=' + xitiParams.globalParam.x2 +
    '&x3=' + xitiParams.globalParam.x3 +
    '&x4=' + xitiParams.globalParam.x4 +
    '&x5=' + xitiParams.globalParam.x5 +
    '&x6=' + xitiParams.globalParam.x6 +
    '&x7=' + xitiParams.globalParam.x7 +
    '&x8=' + xitiParams.globalParam.x8 +
    '&x9=' + xitiParams.globalParam.x9 +
    '&x10=' + xitiParams.globalParam.x10 +
    '&x11=' + xitiParams.globalParam.x11 +
    '&x12=' + xitiParams.globalParam.x12;
}

function sendAnalytic(elem, type, xtn2, xtpage, clickType) {
  if (window.xtsd && window.xtsite) {
    if (clickType) {
      window.xt_click(elem, type, xtn2, xtpage + getGlobalParam(), clickType);
    } else {
      window.xt_click(elem, type, xtn2, xtpage + getGlobalParam() + getReferrerOnInit());
    }
  }
}


module.exports = {
  xitiParams,
  sendAnalytic
};
