'use strict';
/*global someFunction Routing:true*/

var EkUtils = require('../helpers/ekUtils');
var $ = require('jquery');

// custom constraints helpers

var countryCodes = ['AF', 'ZA', 'AL', 'DZ', 'DE', 'AD', 'AO', 'AI', 'AQ', 'AG', 'AN', 'SA', 'AR', 'AM', 'AW', 'AU', 'AT' +
'AZ', 'BS', 'BH', 'BD', 'BB', 'BY', 'BE', 'BZ', 'BJ', 'BM', 'BT', 'BO', 'BA', 'BW', 'BR', 'BN', 'BG', 'BF', 'BI', 'KH' +
'CM', 'CA', 'CV', 'EA', 'CL', 'CN', 'CY', 'CO', 'KM', 'CG', 'KP', 'KR', 'CR', 'CI', 'HR', 'CU', 'CW', 'DK', 'DG', 'DJ' +
'DM', 'EG', 'SV', 'AE', 'EC', 'ER', 'ES', 'EE', 'VA', 'FM', 'US', 'ET', 'FJ', 'FI', 'FR', 'GA', 'GM', 'GE', 'GS', 'GH' +
'GI', 'GR', 'GD', 'GL', 'GP', 'GU', 'GT', 'GG', 'GN', 'GQ', 'GW', 'GY', 'GF', 'HT', 'HN', 'HU', 'BV', 'CX', 'CP', 'AC' +
'IM', 'NF', 'AX', 'KY', 'IC', 'CC', 'CK', 'UM', 'FO', 'HM', 'FK', 'MP', 'MH', 'SB', 'TC', 'VG', 'VI', 'IN', 'ID', 'IQ' +
'IR', 'IE', 'IS', 'IL', 'IT', 'JM', 'JP', 'JE', 'JO', 'KZ', 'KE', 'KG', 'KI', 'KW', 'LA', 'LS', 'LV', 'LB', 'LR', 'LY' +
'LI', 'LT', 'LU', 'MK', 'MG', 'MY', 'MW', 'MV', 'ML', 'MT', 'MA', 'MQ', 'MU', 'MR', 'YT', 'MX', 'MD', 'MC', 'MN', 'ME' +
'MS', 'MZ', 'MM', 'NA', 'NR', 'NP', 'NI', 'NE', 'NG', 'NU', 'NO', 'NC', 'NZ', 'OM', 'UG', 'UZ', 'PK', 'PW', 'PA', 'PG' +
'PY', 'NL', 'BQ', 'PE', 'PH', 'PN', 'PL', 'PF', 'PR', 'PT', 'QA', 'HK', 'MO', 'QO', 'CF', 'CD', 'DO', 'CZ', 'RE', 'RO' +
'GB', 'RU', 'RW', 'EH', 'BL', 'KN', 'SM', 'MF', 'SX', 'PM', 'VC', 'SH', 'LC', 'WS', 'AS', 'ST', 'SN', 'RS', 'SC', 'SL' +
'SG', 'SK', 'SI', 'SO', 'SD', 'SS', 'LK', 'SE', 'CH', 'SR', 'SJ', 'SZ', 'SY', 'TJ', 'TW', 'TZ', 'TD', 'TF', 'IO', 'PS' +
'TH', 'TL', 'TG', 'TK', 'TO', 'TT', 'TA', 'TN', 'TM', 'TR', 'TV', 'UA', 'EU', 'UY', 'VU', 'VE', 'VN', 'WF', 'YE', 'ZM', 'ZW'];

// cf http://www.lingua-systems.com/unicode-converter/unicode-mappings/encode-iso-8859-15-to-utf-8-unicode.html
var iso_8859_15_codePoints = "\\u00FF\\u00FE\\u00FD\\u00FC\\u00FB\\u00FA\\u00F9\\u00F8\\u00F7\\u00F6\\u00F5" +
  "\\u00F4\\u00F3\\u00F2\\u00F1\\u00F0\\u00EF\\u00EE\\u00ED\\u00EC\\u00EB\\u00EA\\u00E9\\u00E8\\u00E7\\u00E6\\u00E5" +
  "\\u00E4\\u00E3\\u00E2\\u00E1\\u00E0\\u00DF\\u00DE\\u00DD\\u00DC\\u00DB\\u00DA\\u00D9\\u00D8\\u00D7\\u00D6\\u00D5" +
  "\\u00D4\\u00D3\\u00D2\\u00D1\\u00D0\\u00CF\\u00CE\\u00CD\\u00CC\\u00CB\\u00CA\\u00C9\\u00C8\\u00C7\\u00C6\\u00C5" +
  "\\u00C4\\u00C3\\u00C2\\u00C1\\u00C0\\u00BF\\u0178\\u0153\\u0152\\u00BB\\u00BA\\u00B9\\u017E\\u00B7\\u00B6\\u00B5" +
  "\\u017D\\u00B3\\u00B2\\u00B1\\u00B0\\u00AF\\u00AE\\u00AD\\u00AC\\u00AB\\u00AA\\u00A9\\u0161\\u00A7\\u0160\\u00A5" +
  "\\u20AC\\u00A3\\u00A2\\u00A1\\u007E\\u007D\\u007C\\u007B\\u007A\\u0079\\u0078\\u0077\\u0076\\u0075\\u0074\\u0073" +
  "\\u0072\\u0071\\u0070\\u006F\\u006E\\u006D\\u006C\\u006B\\u006A\\u0069\\u0068\\u0067\\u0066\\u0065\\u0064\\u0063" +
  "\\u0062\\u0061\\u0060\\u005F\\u005E\\u005D\\u005C\\u005B\\u005A\\u0059\\u0058\\u0057\\u0056\\u0055\\u0054\\u0053" +
  "\\u0052\\u0051\\u0050\\u004F\\u004E\\u004D\\u004C\\u004B\\u004A\\u0049\\u0048\\u0047\\u0046\\u0045\\u0044\\u0043" +
  "\\u0042\\u0041\\u0040\\u003F\\u003E\\u003D\\u003C\\u003B\\u003A\\u0039\\u0038\\u0037\\u0036\\u0035\\u0034\\u0033" +
  "\\u0032\\u0031\\u0030\\u002F\\u002E\\u002D\\u002C\\u002B\\u002A\\u0029\\u0028\\u0027\\u0026\\u0025\\u0024\\u0023" +
  "\\u0022\\u0021\\u0020";

// cf http://www.lingua-systems.com/unicode-converter/unicode-mappings/encode-iso-8859-1-to-utf-8-unicode.html
var iso_8859_1_codePoints = "\\u00FF\\u00FE\\u00FD\\u00FC\\u00FB\\u00FA\\u00F9\\u00F8\\u00F7\\u00F6\\u00F5" +
  "\\u00F4\\u00F3\\u00F2\\u00F1\\u00F0\\u00EF\\u00EE\\u00ED\\u00EC\\u00EB\\u00EA\\u00E9\\u00E8\\u00E7\\u00E6\\u00E5" +
  "\\u00E4\\u00E3\\u00E2\\u00E1\\u00E0\\u00DF\\u00DE\\u00DD\\u00DC\\u00DB\\u00DA\\u00D9\\u00D8\\u00D7\\u00D6\\u00D5" +
  "\\u00D4\\u00D3\\u00D2\\u00D1\\u00D0\\u00CF\\u00CE\\u00CD\\u00CC\\u00CB\\u00CA\\u00C9\\u00C8\\u00C7\\u00C6\\u00C5" +
  "\\u00C4\\u00C3\\u00C2\\u00C1\\u00C0\\u00BF\\u00BE\\u00BD\\u00BC\\u00BB\\u00BA\\u00B9\\u00B8\\u00B7\\u00B6\\u00B5" +
  "\\u00B4\\u00B3\\u00B2\\u00B1\\u00B0\\u00AF\\u00AE\\u00AD\\u00AC\\u00AB\\u00AA\\u00A9\\u00A8\\u00A7\\u00A6\\u00A5" +
  "\\u00A4\\u00A3\\u00A2\\u00A1\\u007E\\u007D\\u007C\\u007B\\u007A\\u0079\\u0078\\u0077\\u0076\\u0075\\u0074\\u0073" +
  "\\u0072\\u0071\\u0070\\u006F\\u006E\\u006D\\u006C\\u006B\\u006A\\u0069\\u0068\\u0067\\u0066\\u0065\\u0064\\u0063" +
  "\\u0062\\u0061\\u0060\\u005F\\u005E\\u005D\\u005C\\u005B\\u005A\\u0059\\u0058\\u0057\\u0056\\u0055\\u0054\\u0053" +
  "\\u0052\\u0051\\u0050\\u004F\\u004E\\u004D\\u004C\\u004B\\u004A\\u0049\\u0048\\u0047\\u0046\\u0045\\u0044\\u0043" +
  "\\u0042\\u0041\\u0040\\u003F\\u003E\\u003D\\u003C\\u003B\\u003A\\u0039\\u0038\\u0037\\u0036\\u0035\\u0034\\u0033" +
  "\\u0032\\u0031\\u0030\\u002F\\u002E\\u002D\\u002C\\u002B\\u002A\\u0029\\u0028\\u0027\\u0026\\u0025\\u0024\\u0023" +
  "\\u0022\\u0021\\u0020";

var forbiddenPasswords = [
  "000000", "111111", "222222", "333333", "444444", "555555", "666666", "777777", "888888", "999999"
];

/* siret/siren check helpers */
function _hasSirenSize(value) {
  return value.length === 9;
}

function _hasSiretSize(value) {
  return value.length === 14;
}

function _isLuhnValid(value) {
  return _isMultipleOf10(_getLuhnSum(value));
}

function _getLuhnSum(value) {
  var digitSum = 0,
    alternate = false,
    digitIndex,
    currentDigit;

  for (digitIndex = value.length - 1; digitIndex >= 0; digitIndex--) {
    currentDigit = _extractCurrentDigit(value, digitIndex);
    if (alternate) {
      currentDigit = _multiplyPer2andSplitIfNecessary(currentDigit);
    }
    digitSum += currentDigit;
    alternate = !alternate;
  }
  return digitSum;
}

function _extractCurrentDigit(value, digitIndex) {
  return parseInt(value.substring(digitIndex, digitIndex + 1), 10);
}

function _multiplyPer2andSplitIfNecessary(currentDigit) {
  currentDigit *= 2;
  if (currentDigit > 9) {
    currentDigit -= 9;
  }
  return currentDigit;
}

function _isMultipleOf10(digitSum) {
  return (digitSum % 10 === 0);
}
/* ENDOF siret/siren check helpers */



// provide global validators to JsFormValidatorBundle

window.DigiposteBundleRestBundleValidatorConstraintsAddressField = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    // there is currently no specific constraint for this anymore, so we just return with no error
    // we keep this as the backend still sends it, and it may evolve at some point
    return [];
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsDigits = function () {

  // This value will be filled with the real message received from your php constraint
  this.message = '';


  // This method is required
  // Should return an error message or an array of messages
  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = (!value || EkUtils.isNumeric(value));

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };

  // Optional method
  this.onCreate = function () {
    // You can put here some extra actions which will be called after build of this constraint
    // E.g. you can make some preparing actions with the properties
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsEmail = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = value.length && /^[\w._%+-]+@[\w.-]+\.[a-z]{2,4}$/.test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsEmailDigiposte = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = value.length &&
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-z0-9!#$%&'*+/=?^_`{|}~-]+(\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)+)$/
        .test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsISO_8859_1 = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = value.length && new RegExp("^[" + iso_8859_1_codePoints + "]+$").test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsISO_8859_15 = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = value.length && new RegExp("^[" + iso_8859_15_codePoints + "]+$").test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsPasswordAllowed = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = forbiddenPasswords.indexOf(value) === -1;

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsDatePast = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = (new Date().getTime() > new Date(value).getTime());

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsPostalCode = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = EkUtils.isNullOrUndefined(value) || value === '' || /^(0[1-9]|[1-9][0-9])[0-9]{3}$/.test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsForeignPostalCode = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = EkUtils.isNullOrUndefined(value) || value === '' || /^[\w\s]+$/.test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };

};

window.DigiposteBundleRestBundleValidatorConstraintsLocality = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = EkUtils.isNullOrUndefined(value) || value === '' || /^[\w\s!"#$%&'()*+,\-.\\/:;<=>?@[\\\]^_`{|}~]+$/.test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };
};

window.DigiposteBundleRestBundleValidatorConstraintsForeignLocality = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = EkUtils.isNullOrUndefined(value) || value === '' || /^[\w\s]+$/.test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };
};

window.DigiposteBundleRestBundleValidatorConstraintsSirenSiret = function () {

  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    if (EkUtils.isNullOrUndefined(value) || value === '') {
      isValid = true;
    } else if (_hasSirenSize(value) || _hasSiretSize(value)) {
      isValid = _isLuhnValid(value);
    } else {
      isValid = false;
    }

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };
};

window.DigiposteBundleRestBundleValidatorConstraintsURL = function () {
  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = EkUtils.isUrl(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };
};

window.DigiposteBundleRestBundleValidatorConstraintsEmailCollection = function () {
  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = /^[\w._%+-]+@[\w.-]+\.[a-z]{2,4}$/.test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };
};

window.DigiposteBundleRestBundleValidatorConstraintsPhoneNumber = function () {
  this.message = '';

  this.validate = function (value, FpJsFormElement) {
    var isValid;

    isValid = /^((0[1-9])|(\+[0-9]{2}[ ]?[0-9]))([ -\.]?[0-9]{2}){4}$/.test(value);

    if (!isValid) {
      return this.message.replace('%string%', value);
    } else {
      return [];
    }
  };
};

/*------------------
  DIGIPOSTE +
--------------------*/

/*
  Shared functions check
*/
function checkPassValue(value){

  var nbChar = false;
  var letter = false;
  var number = false;
  var lowercase = false;
  var uppercase = false;
  var max = false;
  var i;

  var lettersRegexp = /[a-zA-ZÄÂÀÁÃàáâãäåËÊÈÉéèëêÏÎìíîïÖÔÒÓÕòóôõöÜÛÙÚùúûüŸỲÝỳýÿÇçðÑñ]/;
  var numbersRegex = /[0-9]/;

  // check 8 char
  if (value.length >= 8) {
    nbChar = true;
  }

  // check 128 char
  if (value.length <= 128) {
    max = true;
  }

  // check 1 letter
  if (value.length > 0 && lettersRegexp.test(value)) {
    letter = true;
  }

  // check 1 number
  if (value.length > 0 && numbersRegex.test(value)) {
    number = true;
  }
  
  //check 1 lowercase
  if(value.length > 0 && value != value.toLowerCase()){
    uppercase = true; 
  }
  //check 1 uppercase
  if(value.length > 0 && value != value.toUpperCase()){
    lowercase = true;
  }
  
  return [nbChar, letter, number, max, lowercase, uppercase];
}

function checkEmail(value){
  return /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(value);
}

function checkNbCharsValue(value){
  var flag = true;
  if (value.length > 64) {
    flag = false;
  }
  return flag;
}

function checkNoSpecialCharsValue(value){
  var flag = true;
  var isValid = /^[-'0-9a-zA-ZÄÂÀÁÃàáâãäåËÊÈÉéèëêÏÎìíîïÖÔÒÓÕòóôõöÜÛÙÚùúûüŸỲÝỳýÿÇçðÑñ ]*$/.test(value);

  if (!isValid) {
    flag = false;
  }
  return flag;
}


var xhrEmailExists;
function ajaxEmailExists(email, successFunc, faultFunc) {
  if (xhrEmailExists) xhrEmailExists.abort();
  xhrEmailExists = $.ajax({
    url: Routing.generate('user__email_exist', { email: email }),
    type: 'POST',
    success: function(data, textStatus, xhr) {
      if (data.isEmailExisted === true) {
        if (successFunc) {
          successFunc();
        }
      } else {
        if (faultFunc) {
          faultFunc();
        }
      }
    }, complete: function () {
      xhrEmailExists = false;
    }
  });
}

var xhrCcuEmailExists;

function ajaxCcuEmailExists(email, successFunc, faultFunc) {
  if (xhrCcuEmailExists) xhrCcuEmailExists.abort();
  xhrCcuEmailExists = $.ajax({
    url: Routing.generate('user__ccu_email_exist', { email: email }),
    type: 'POST',
    success: function(data, textStatus, xhr) {
      if (data.isEmailExisted === true) {
        if (successFunc) {
          successFunc();
        }
      } else {
        if (faultFunc) {
          faultFunc();
        }
      }
    }, complete: function () {
      xhrCcuEmailExists = false;
    }
  });
}


module.exports = {
  checkEmail,
  checkPassValue,
  checkNbCharsValue,
  checkNoSpecialCharsValue,
  ajaxEmailExists,
  ajaxCcuEmailExists
};
