'use strict';
var $ = require('jquery');

var _InputDigiplus = function(elementJquery){

  var input = $(elementJquery.find('input')[0]);
  var label = $(elementJquery.find('label')[0]);
  var error = elementJquery.find('.formErrorMessage');
  var lastValue = null;
  var _ref = this;

  this.value = '';
  this.onChange = null;

  /**
    * init component
    * @returns {void}
    * @private
  */
  var init = function () {
    lastValue = input.val();

    // set id on element parent
    if (!elementJquery.attr('id') && input.attr('name')) {
      elementJquery.attr('id', 'inputCT_' + input.attr('name'));
    }

    if (input.val() !== '') {
      elementJquery.addClass("notempty");
    }

    if (error && error.length > 0) {
      elementJquery.addClass("error");
    }

    refreshInput();

    input.on("focusin", function(){
      elementJquery.addClass("focus");
    });

    input.on("focusout", function(){
      elementJquery.removeClass("focus");

      refreshInput();
    });

    input.on("change", function(){
      refreshInput();
      _ref.value = input.val();
    });

    label.on("click", function(){
      input.focus();
    });
  };

  /**
    *
    * @returns {void}
    * @private
  */
  var refreshInput = function(){
    if (input.val() === '') {
      elementJquery.removeClass("notempty");
    } else {
      elementJquery.addClass("notempty");
    }
    if (elementJquery.hasClass("error")) {
      if (input.val() !== lastValue) {
        lastValue = input.val();
        var errorDiv = elementJquery.find('.formErrorMessage');
        errorDiv.remove();
        elementJquery.removeClass("error");
      }
    }

    if (_ref.onChange) {
      _ref.onChange();
    }
  };

  /**
    *
    * @param {string} value value of the input text
    * @public
    * @returns {void}
  */
  this.setValue = function(value) {
    input.val(value);
    refreshInput();
  };


  init();

  return this;
};

function newInput(elementJquery){
  return new _InputDigiplus(elementJquery);
}

module.exports = {
  newInput
};
