'use strict';

var config = {};

if (window.EKS && window.EKS.DGP && window.EKS.DGP.settings) {
  Object.assign(config, window.EKS.DGP.settings);
}

// config app smart-app-banner
if (!config.smartbanner) {
  config.smartbanner = {};
}
config.smartbanner.daysHidden = 15;
config.smartbanner.daysReminder = 90;


module.exports = config;
