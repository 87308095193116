'use strict';

// vendor dependencies
var $ = require('jquery');
require('jquery-ui/dialog');

// expose jQuery on the global object so that fp_js_validator.js script can find it and hook onto it
window.jQuery = window.$ = $;

// IE8/9 polyfills
require('../polyfills/event-listener');
require('../polyfills/html-element');

var validationConstraints = require('./validation-custom-constraints');

require('./validation-custom-constraints');
var config = require('../config');
var data = require('../data');
var tabs = require('../tabs');
var analytics = require('../analytics');

var validables = 'input, select, textarea';
var errorsClass = 'formErrorMessage';
var $multipleStepsForm;
var $singleStepForm;
var currentStepIndex;
var $body = $('body');

$(document).ready(function () {

  initValidationModal();

  if (!$.fn.jsFormValidator) {
        console.warn("jsFormValidator is missing.");
        return;
    }

    $multipleStepsForm = $('.js-multipleStepsFormValidation');
    $singleStepForm = $('.js-singleStepFormValidation');

    initMultipleStepsFormValidation();
    initSingleStepFormValidation();

    handleBackendErrors();
    checkIfEmailExists();
    checkIfCcuEmailExists();
});

function initValidationModal() {
    var validationError = data.validationError;
    var modalMarkup;
    var $modalContent;

    if (!validationError) {
        return;
    }

    if (validationError.type === 'LOGIN_FAILED') {
        tabs.tabChangeTo(1);
    }

    modalMarkup = `
  <div id="dialog" title="${validationError.title}">
    ${validationError.content}
  </div>
  `;

    $modalContent = $(modalMarkup);
    $body.append($modalContent);

    $modalContent.dialog({
        modal: true,
        dialogClass: 'validationErrorModal',
        width: 'auto'
    });

}

function showErrors(errors, sourceId) {
    var $this = $(this);

    $('#formErrorMessage--' + sourceId).remove();

    if (errors.length) {
        var $errorReport = $('<p></p>', {
            'class': errorsClass,
            'id': 'formErrorMessage--' + sourceId,
            'text': errors[0]
        });

        $this.parent().append($errorReport);

    }
}

function handleBackendErrors () {
    var $backendError,
        $currentStep,
        nextStepIndex,
        $form;

    $backendError = $('.' + errorsClass);

    if (!$backendError.length) {
        return;
    }

    $form = $backendError.parents('form');
    if ($form.length && !$form.hasClass('multipleStepsForm')) {
        if ($form.prop('id') === 'loginForm') {
            tabs.tabChangeTo(1);
        }
        // form doesn't have steps, so we can leave early
        return;
    }

    if ($form.prop('id') === 'registerForm') {
        tabs.tabChangeTo(0);
    }

    $currentStep = $backendError.parents('.ui-tabs-panel');

    if (!$currentStep.length) {
        return;
    }

    nextStepIndex = Number($currentStep.prop('id').split('step')[1]) - 1;

    // Forces registration form to step 2 (as the only back-end error that can occurs seems to be on this second step).
    if ($form.prop('id') === 'registerForm') {
        nextStepIndex = 1;
    }

    tabs.stepChangeTo(nextStepIndex);
    currentStepIndex = nextStepIndex;
}

function initMultipleStepsFormValidation() {
    var $steps;


    if (!$multipleStepsForm.length) {
        return;
    }

    currentStepIndex = 0;

    $steps = [];
    $('.js-steps-tabs').find('.ui-tabs-anchor').each((index, tabAnchor) => {
        var $tabAnchor = $(tabAnchor);
    var $tabContent = $($tabAnchor.attr('href'));
    $steps.push($tabContent);
});

    getValidables($multipleStepsForm).jsFormValidator({
        showErrors
    });

    $multipleStepsForm.on("click", ".formFooter_submit", handleStepSubmit);
    $multipleStepsForm.on("click", ".formFooter_cancel", gotoPrevStep);
    $(window).on('popstate', function (e) {
        if ($multipleStepsForm.length && $(".loginPage_tabs_tab:first").hasClass("ui-tabs-active")) {
            gotoPrevStep();
        }
    });

    function handleStepSubmit(e) {
        var $errors;
        let additionnalParams;
        let i = 0;

        // cleanup previous (serverside) errors
        $steps[currentStepIndex].find('.' + errorsClass).remove();

        // prevent form submission
        e.preventDefault();

        getValidables($steps[currentStepIndex]).each(function (index) {
            $(this).jsFormValidator('validate');
        });

        checkCivilityRadio();

        $errors = $steps[currentStepIndex].find('.' + errorsClass).not("#user_account_input_email_exists");

        if (!$errors.length) {
            gotoNextStep();
        } else {
          if (currentStepIndex === 0) {
            additionnalParams = '&f1={f1}&f2={f2}&f3={f3}';
            for (i; i < $errors.length; i++) {
              if ($errors[i].id.match(/user-account-input-title/) ) {
                additionnalParams = additionnalParams.replace(/\{f1\}/, "1");
              }
              if ($errors[i].id.match(/user-account-input-dateOfBirth/)) {
                additionnalParams = additionnalParams.replace(/\{f2\}/, "1");
              }
              if ($errors[i].id.match(/user-account-input-email/)) {
                additionnalParams = additionnalParams.replace(/\{f3\}/, "1");
              }
            }
          } else {
            additionnalParams = '&f1={f1}&f2={f2}&f3={f3}&f4={f4}&f5={f5}';
            for (i; i < $errors.length; i++) {
              if ($errors[i].id.match(/user-account-input-login/) ) {
                additionnalParams = additionnalParams.replace(/\{f1\}/, "1");
              }
              if ($errors[i].id.match(/user-account-input-password-input/)) {
                additionnalParams = additionnalParams.replace(/\{f2\}/, "1");
              }
              if ($errors[i].id.match(/user-account-input-password-inputConfirmation/)) {
                additionnalParams = additionnalParams.replace(/\{f3\}/, "1");
              }
              if ($errors[i].id.match(/user-account-input-secretQuestionIndex/)) {
                additionnalParams = additionnalParams.replace(/\{f4\}/, "1");
              }
              if ($errors[i].id.match(/user-account-input-secretAnswer/)) {
                additionnalParams = additionnalParams.replace(/\{f5\}/, "1");
              }
            }
          }

          additionnalParams = additionnalParams
            .replace(/\{f1\}/, "0")
            .replace(/\{f2\}/, "0")
            .replace(/\{f3\}/, "0")
            .replace(/\{f4\}/, "0")
            .replace(/\{f5\}/, "0");

          analytics.sendAnalytic(e.currentTarget, 'F', 1, "coregistration::votre_compte" + additionnalParams);
        }

    }

    function gotoNextStep() {
        var nextStepIndex = currentStepIndex + 1;

        if ($steps[nextStepIndex]) {
            tabs.stepChangeTo(nextStepIndex);
            currentStepIndex = nextStepIndex;
        } else {
            sendForm();
        }

        if ($('.loginPage_tabs').length) {
            $(document).scrollTop($('.loginPage_tabs').offset().top);
        }
    }

    function gotoPrevStep(e) {
        var prevStepIndex = currentStepIndex - 1;

        if (e) {
            e.preventDefault();
        }

        if ($steps[prevStepIndex]) {
            tabs.stepChangeTo(prevStepIndex);
            currentStepIndex = prevStepIndex;
        }

        if ($('.loginPage_tabs').length) {
            $(document).scrollTop($('.loginPage_tabs').offset().top);
        }
    }

    function sendForm() {
        $multipleStepsForm.submit();
    }
}

function initSingleStepFormValidation() {

    if (!$singleStepForm.length) {
        return;
    }

    getValidables($singleStepForm).jsFormValidator({
        showErrors
    });

    $singleStepForm.on("click", ".formFooter_submit", handleSubmit);

    function handleSubmit(e) {
        var $errors;

        // cleanup previous (serverside) errors
        $singleStepForm.find('.' + errorsClass).remove();

        // prevent form submission
        e.preventDefault();

        getValidables($singleStepForm).each(function (index) {
            $(this).jsFormValidator('validate');
        });

        $errors = $singleStepForm.find('.' + errorsClass);

        if (!$errors.length) {
            $singleStepForm.submit();
        }

    }


}


function getValidables(node) {
    // we need to filter out the disabled/hidden elements or fpJsValidator will crash on the 'validate' call
    //return $(node).find(validables).not(":disabled").not('[type="hidden"]').not('[type="radio"]');
    return $(node).find(validables).not(":disabled").not('[type="hidden"]').not('[type="radio"]');
}

function ajaxEmailExists(input) {
  validationConstraints.ajaxEmailExists($(input).val(), function() {
    if (!$("#user_account_input_email_exists").length) {
      var html = '<p class="formErrorMessage" id="user_account_input_email_exists">'
        + $("#email_duplicate_error").text() + '</p>';
      $("#user_account_input_email").after(html);
    }
  }, function(){
    $("#user_account_input_email_exists").remove();
  });
}

function ajaxCcuEmailExists(input) {
  validationConstraints.ajaxCcuEmailExists($(input).val(), function() {
    if (!$("#user_account_input_email_exists").length) {
      var html = '<p class="formErrorMessage" id="user_account_input_email_exists">'
        + $("#email_duplicate_error").text() + '</p>';
      $("#user_ccu_primaryEmail").after(html);
    }
  }, function(){
    $("#user_account_input_email_exists").remove();
  });

}

function checkIfEmailExists() {
    $("#user_account_input_email.check_email_exists").on("propertyChange change keyup", function (e) {
      if ($(this).val().length > 5 && $(this).val().indexOf("@") > -1) {
          ajaxEmailExists($("#user_account_input_email"));
      } else {
          $("#user_account_input_email_exists").remove();
      }
    });
    if ($("#user_account_input_email.check_email_exists").length && $("#user_account_input_email.check_email_exists").val()) {
      ajaxEmailExists($("#user_account_input_email"));
    }
}

function checkIfCcuEmailExists() {
    $("#user_ccu_primaryEmail.check_email_exists").on("propertyChange change keyup", function (e) {
        if ($(this).val().length > 5 && $(this).val().indexOf("@") > -1) {
            ajaxCcuEmailExists($("#user_ccu_primaryEmail"));
        } else {
            $("#user_account_input_email_exists").remove();
        }
    });
    if ($("#user_ccu_primaryEmail.check_email_exists").length && $("#user_ccu_primaryEmail.check_email_exists").val()) {
        ajaxCcuEmailExists($("#user_ccu_primaryEmail"));
    }
}

function checkCivilityRadio() {
    if (!$("input.civilityRadio_radioBtn:checked").length) {
        var html = '<p class="formErrorMessage" id="formErrorMessage--form-error-user-account-input-title">'
          + $("#civility_blank_error").text() + '</p>';
        $(".civilityRadio_list").after(html);
    } else {
        $("#user_account_input_title").remove();
    }
}
