'use strict';


// vendor dependencies
var $ = require('jquery');

$(document).ready(function () {
  var $headernavButton = $(".js-headerNav-button");
  var $headernavDropdown = $(".js-headerNav-dropdown");

  $headernavButton.on("click", function(e) {
    var $this = $(this);
    if (!$this.siblings(".js-headerNav-dropdown:first").is(":visible")) {
      $headernavDropdown.hide();
      $this.siblings(".js-headerNav-dropdown:first").show();
    } else {
      $this.siblings(".js-headerNav-dropdown:first").hide();
    }
    e.stopPropagation();
  });

  $headernavDropdown.on("click", function(e) {
    e.stopPropagation();
  });

  $("body").on("click", function(e) {
    $headernavDropdown.hide();
  });

});
