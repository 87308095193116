'use strict';
var $ = require('jquery');

var _CheckboxRadioDigiplus = function(elementJquery){

  this.onChange = null;
  this.checked = false;
  this.element = elementJquery;

  var _ref = this;
  var input = $(elementJquery.find('input')[0]);
  var checkboxCt = $(elementJquery.find('.digiplus_checkbox')[0]);
  var radioCt = $(elementJquery.find('.digiplus_radio')[0]);
  var allRadioInput = $('input[type=radio][name="' + input.attr('name') + '"]');
  var error = elementJquery.find('.formErrorMessage');
  var ctErrors;

  /**
    * init component
    * @returns {void}
    * @private
  */
  var init = function () {
    // set id on element parent
    if (!elementJquery.attr('id') && input.attr('name')) {
      elementJquery.attr('id', 'inputCT_' + input.attr('name') + '_' + input.attr('value'));
    }

    if (error && error.length > 0) {
      elementJquery.addClass("error");
    }

    // case type radio
    if (radioCt.length && radioCt.length > 0) {
      var idErrorsCt = input.attr('data-js-error-container');
      if (idErrorsCt && idErrorsCt !== '') {
        ctErrors = $('#' + idErrorsCt);
      } else {
        ctErrors = input.parent();
      }
    } else {
      ctErrors = input.parent();
    }

    refreshSelected();

    input.on("focusin", function(){
      elementJquery.addClass("focus");
    });

    input.on("focusout", function(){
      elementJquery.removeClass("focus");
    });

    input.on("change", function() {

      refreshSelected();

    });
  };

  /**
    *
    * @returns {void}
    * @private
  */
  var refreshSelected = function() {
    if (radioCt.length && radioCt.length > 0) {
      if (input.is(':checked')) {

        for (var i = 0; i < allRadioInput.length; i++) {
          $(allRadioInput[i]).parent().removeClass('checked');
          $(allRadioInput[i]).parent().parent().removeClass("error");
        }

        radioCt.addClass('checked');

      } else {
        radioCt.removeClass('checked');
      }

    } else {
      if (input.is(':checked')) {
        checkboxCt.addClass('checked');
        _ref.checked = true;
      } else {
        checkboxCt.removeClass('checked');
        _ref.checked = false;
      }
    }

    var errorDiv = ctErrors.find('.formErrorMessage');
    errorDiv.remove();
    elementJquery.removeClass("error");

    if (_ref.onChange) {
      _ref.onChange(input);
    }
  };

  /**
    *
    * @param {boolean} value value of the checkbox or the radiobutton
    * @returns {void}
    * @public
  */
  this.setValue = function(value) {
    if (radioCt.length && radioCt.length > 0) {

    } else { // case checkBox
      input[0].checked = value;
      if (value === true) {
        checkboxCt.addClass('checked');
        _ref.checked = true;
      } else {
        checkboxCt.removeClass('checked');
        _ref.checked = false;
      }
    }
  };

  init();
};


function newInput(elementJquery){
  return new _CheckboxRadioDigiplus(elementJquery);
}

module.exports = {
  newInput
};
