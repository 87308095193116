'use strict';

// vendor dependencies
var $ = require('jquery');

$(document).ready(function () {
  var $langswitcherButton = $(".js-langswitcher-button");
  var $langswitcher = $(".js-langswitcher");

  $langswitcherButton.on("click", function(e) {
    if (!$langswitcher.filter(":first").is(":visible")) {
      $langswitcher.show();
    } else {
      $langswitcher.hide();
    }
    e.stopPropagation();
  });

  $langswitcher.on("click", function(e) {
    e.stopPropagation();
  });

  $("body").on("click", function(e) {
    $langswitcher.hide();
  });

});
