"use strict";

var _toString = Object.prototype.toString;

var utils = {

  getClassOf: function (object) {
    return _toString.call(object).match(/^\[object\s(\w*)\]$/)[1];
  },

  isArray: function (object) {
    if (typeof object !== 'object') {
      return false;
    }
    return _toString.call(object) === '[object Array]';
  },

  isBoolean: function (object) {
    return object === !!object || object instanceof Boolean;
  },

  isDate: function (object) {
    return _toString.call(object) === '[object Date]';
  },

  isUtcDate: function (object) {
    if (!this.isString(object)) {
      return false;
    }
    return /^\d{4}-(0\d|1[012])-([0-2]\d|3[01])$/.test(object);

  },

  isUtcDatetime: function (object) {
    if (!this.isString(object)) {
      return false;
    }
    return /^\d{4}-(0\d|1[012])-([0-2]\d|3[01])T([01]\d|2[0-3]):[0-5]\d:[0-5]\d([+-]([01]\d|2[0-4]):?[0-5]\d|Z)$/.test(object);
  },

  isError: function (object) {
    return _toString.call(object) === '[object Error]';
  },

  isFunction: function (object) {
    return _toString.call(object) === '[object Function]';
  },

  isNumber: function (object) {
    if (typeof object === 'number') {
      return true;
    }
    //
    if (typeof object !== 'object') {
      return false;
    }
    return _toString.call(object) === '[object Number]';
  },
  // cf http://stackoverflow.com/questions/18082/validate-numbers-in-javascript-isnumeric
  isNumeric: function (object) {
    return !isNaN(parseFloat(object)) && isFinite(object);
  },

  isInteger: function (object) {
    if (!this.isNumber(object)) {
      return false;
    }
    return Math.ceil(object) === object;
  },

  isFloat: function (object) {
    if (!this.isNumber(object)) {
      return false;
    }
    return Math.ceil(object) !== object;
  },

  isNull: function (object) {
    return object === null;
  },

  isObject: function (object) {
    if (typeof object !== 'object') {
      return false;
    }
    return _toString.call(object) === '[object Object]';
  },

  isRegExp: function (object) {
    return _toString.call(object) === '[object RegExp]';
  },

  isString: function (object) {
    return (typeof object === 'string') || object instanceof String;
  },

  isUndefined: function (object) {
    return object === undefined;
  },

  isDefined: function (object) {
    return !(this.isUndefined(object));
  },

  isNullOrUndefined: function (object) {
    return object == null;
  },

  //
  isUrl: function (string) {
    if (!this.isString(string)) {
      return false;
    }
    return /^(https?:\/\/)?([\w\d-]+\.)+([a-z\.]{2,6})(\/.+)*$/i.test(string);
  },

  bytesToSize: function (bytes, minimyze) {
    var k = 1024,
      sizes = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'],
      i,
      value,
      unit,
      result;

    if (!this.isDefined(bytes)) {
      return bytes;
    }

    if (!this.isNumber(bytes)) {
      throw new Error("Couldn't get bytesToSize for not a number : " + bytes);
    }

    i = Math.floor(Math.log(bytes) / Math.log(k));
    value = parseInt(bytes / Math.pow(k, i) * 100, 10) / 100;
    unit = sizes[i] || 'Ko';

    if (minimyze && this.isFloat(value)) {
      if (value > 10 && value <= 100) {
        value = Number(value.toFixed(1));
      } else if (value > 100) {
        value = Number(value.toFixed(0));
      }
    }

    result = {
      value: value || 0,
      unit: unit
    };

    return result;
  },

  stripSpecialCar: function (string) {
    string = string.replace(/[^a-zA-Z0-9]/g, '_');
    return string;
  }

};

module.exports = utils;
