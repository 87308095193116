'use strict';
require('./polyfills/console');
require('babel-polyfill');
var $ = require('jquery');
window.SmartBanner = require('smart-app-banner');
window.modal = require('./controller/digiplus/modalCtrl'); // stock it on window to access from html
window.config = require('./config'); // stock it on window to access from html

// scripts
require('./tabs');
require('./validation/validation');
require('./pinpad');
require('./headerNav');
require('./langSwitcher');
require('./browserSupport');
require('./displayCorporateBlock');
require('./cookiesConsent');

// ---------------------------------
// APP DIGIPOSTE + (new js system added for the refonte)
// ---------------------------------
require('./bootstrapDigiplus');

var inputDigiplus = require('./components/inputDigiplus');
var carousel = require('./components/carousel');
var digiplusFormValidation = require('./validation/digiplusFormValidation');
var bubbleInfo = require('./components/bubbleInfo');

// controller pages
var registerConfirmCtrl = require('./controller/digiplus/registerConfirmCtrl');
var pairingAccountCtrl = require('./controller/digiplus/pairingAccountCtrl');
var coregisterCtrl = require('./controller/digiplus/coregisterCtrl');


$(document).ready(function () {

  // declare here all components
  window.APP._addComponent("js-digiplusInput", inputDigiplus.newInstance);
  window.APP._addComponent("js-digiplusFormValidation", digiplusFormValidation.newInstance);
  window.APP._addComponent("js-carousel", carousel.newInstance);
  window.APP._addComponent("js-coregisterCtrl", coregisterCtrl.newInstance);
  window.APP._addComponent("js-pairingAccountCtrl", pairingAccountCtrl.newInstance);
  window.APP._addComponent("js-registerConfirmCtlr", registerConfirmCtrl.newInstance);
  window.APP._addComponent("js-bubbleInfo", bubbleInfo.newInstance);

  //var oldTime = new Date().getTime();
  // build all components founded in the body
  window.APP._buildComponents(document.getElementsByTagName('body')[0]);
  //console.log(new Date().getTime() - oldTime);
});
