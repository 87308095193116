'use strict';

// vendor dependencies
var $ = require('jquery');

$(document).ready(function() {

  var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)dgpCookieConsent\s*\=\s*([^;]*).*$)|^.*$/, "$1");

  if (cookieValue !== "checked") {
    var $banner = $(".js-cookieConsent");
    var $bannerTrigger = $(".cookieConsentBanner_acceptTrigger");

    $banner.addClass('_is_entering');

    if ($bannerTrigger) {
      $bannerTrigger.bind('click', function () {
        $banner.removeClass('_is_entering');

        var date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        document.cookie = 'dgpCookieConsent=checked; expires=' + date.toGMTString() + '; path=/';

      });
    }
  }

});
