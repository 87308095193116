'use strict';


// vendor dependencies
var $ = require('jquery');

var $doublePinpadForm;
var $doublePinpadFormPinpad;
var $currentDoublePinpadFormPasswordField;

$(document).ready(function () {
  initDoublePinpads();
  initSinglePinpadFormPinpad();
  hidePinpadForm();
});

function hidePinpadForm() {
  let $block = $("#pinpadBlock");
  //S'il le form pinpad prèsente des erreurs, on affiche le block pinpad
  if($("div.inputWrapper > .formErrorMessage").length) {
    $block.css('display', 'block');
    $('html, body').stop().animate({
      'scrollTop': $("#pinpadAnchor").offset().top
    }, 900, 'swing');
  }

  $("#hideDisplayPinpad").click(function(e) {
    e.preventDefault();

    if(!$block.is(":visible")) {
      $block.css('display', 'block');

      $('html, body').stop().animate({
        'scrollTop': $("#pinpadAnchor").offset().top
      }, 900, 'swing');
    } else {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
      $block.css('display', 'none');
    }
  });
}

function initDoublePinpads() {
  $doublePinpadForm = $('.js-doublePinpadForm');
  if (!$doublePinpadForm.length) {
    return;
  }
  $doublePinpadFormPinpad = $doublePinpadForm.find('.pinpad');
  $currentDoublePinpadFormPasswordField = $doublePinpadForm.find('.inputWrapper--pinpad .passwordField');

  $('[name=pass_map_double]').find('area').click(handleDoublePinpadFormPinpad);

  $doublePinpadForm.on('click', '.pinpad_reset', function (e) {
    e.preventDefault();

    $currentDoublePinpadFormPasswordField.val('');

  });

  $doublePinpadForm.on('click', '.passwordField', function (e) {
    e.preventDefault();

    $currentDoublePinpadFormPasswordField = $(this);
    positionPinpad();

  });
}

function initSinglePinpadFormPinpad() {
  var $singlePinpadForm = $('.js-singlePinpadForm');
  if (!$singlePinpadForm.length) {
    return;
  }
  var $singlePinpadFormPinpad = $singlePinpadForm.find('.pinpad');
  var $singlePinpadFormPasswordField = $singlePinpadForm.find('.inputWrapper--pinpad .passwordField');

  $('[name=pass_map]').find('area').click(function(e) {
    e.preventDefault();

    var $this = $(this);

    var passwordInput = $singlePinpadFormPasswordField.val();

    if (passwordInput.length < 6) {
      $singlePinpadFormPasswordField.val(passwordInput + $this.data('position'));
    }
  });

  $singlePinpadFormPinpad.on('click', '.pinpad_reset', function (e) {
    e.preventDefault();
    $singlePinpadFormPasswordField.val('');
  });

}

function handleDoublePinpadFormPinpad(e) {
  var $this,
    currentPasswordInput
    ;

  e.preventDefault();

  $this = $(this);

  currentPasswordInput = $currentDoublePinpadFormPasswordField.val();

  if (currentPasswordInput.length < 6) {
    $currentDoublePinpadFormPasswordField.val(currentPasswordInput + $this.data('position'));
  }
  // we don't update the currentPasswordInput after adding value, so we need to compare it with max value minus one
  if (!$currentDoublePinpadFormPasswordField.parents('.inputWrapper').hasClass('inputWrapper--pinpadConfirmation') &&
    currentPasswordInput.length >= 5) {
    $currentDoublePinpadFormPasswordField = $doublePinpadForm.find('.inputWrapper--pinpadConfirmation .passwordField');
    positionPinpad();
  }
}

function positionPinpad() {

  if ($currentDoublePinpadFormPasswordField.parents('.inputWrapper').hasClass('inputWrapper--pinpadConfirmation')) {
    $doublePinpadFormPinpad.addClass('_has_moved');
  } else {
    $doublePinpadFormPinpad.removeClass('_has_moved');
  }

}
