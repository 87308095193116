'use strict';
var $ = require('jquery');

/*
  -------- Controller .js-pairingAccountCtrl ---------
*/
var PairingAccountCtrl = function($element){
  var $accounts = $element.find(".js-carousel-account");
  var $seeMoreBtn = $element.find(".js-carousel-seeMore");
  var $submitBtn = $element.find(".digiplusButton_submit");
  var $inputs = $element.find(".js-carousel-account-input");
  var loginSelectHided = $element.find("#login_login");

  var accountsAvailable = [];
  var i;

  /**
    * init component
    * @returns {void}
    * @private
  */
  var init = function () {
    for (i = 0; i < $accounts.length; i++) {
      if (!$accounts.eq(i).hasClass('dgpCarousel_account-disabled')) {
        accountsAvailable.push(i);
      }
    }

    if (accountsAvailable.length === 1 && !$accounts.eq(0).hasClass('dgpCarousel_account-disabled')) {
      selectAccount($accounts.eq(accountsAvailable[0]));
    }
    else {
      disableSubmitButton();
    }

    seeMoreOnClick();
    clickOnAccount();

  };

  /**
    *
    * @returns {void}
    * @private
  */
  var seeMoreOnClick = function(){
    var lastHiddenAccount = 0;
    var firstAccountToShow = 0;
    var count = 4;
    var g;

    if ($accounts.length <= count) {
      $seeMoreBtn.remove();
    }
    else {
      for (g = count; g < $accounts.length ; g++) {
        $accounts.eq(g).addClass('hidden');
      }
    }

    $('.js-carousel-seeMore').on("click", function() {
      /* Hidden accounts if there is more than 4 */
      for (var j = 0 ; j < $accounts.length ; j++) {
        if (!$accounts.eq(j).hasClass('hidden')){
          lastHiddenAccount = j;
        }

        if ($accounts.eq(j).hasClass('hidden') && (lastHiddenAccount === (j - 1))){
          firstAccountToShow = j;
        }
      }

      if ((firstAccountToShow + count) > $accounts.length) {
        count = $accounts.length - firstAccountToShow;
      }

      /* Show 4 more accounts at click */
      if (lastHiddenAccount !== 0 && firstAccountToShow !== 0){
        for (var k = firstAccountToShow ; k < (firstAccountToShow + count) ; k++) {
          $accounts.eq(k).removeClass('hidden');
        }

        firstAccountToShow += count;
      }

      /* Remove button when there is no more account to show */
      if ($accounts.length === firstAccountToShow) {
        $seeMoreBtn.remove();
      }
    });
  };

  /**
    *
    * @returns {void}
    * @private
  */
  var disableSubmitButton = function () {
    for (var g = 0 ; g < ($accounts.length) ; g++) {
      if ($accounts.eq(g).hasClass('dgpCarousel_account-selected')) {
        $submitBtn.attr('disabled', false);
      }
      else {
        $submitBtn.attr('disabled', 'disabled');
      }
    }
  };

  /**
    *
    * @returns {void}
    * @private
  */
  var clearAccounts = function() {
    $inputs.parent().removeClass('checked');
    $accounts.removeClass('dgpCarousel_account-selected');
  };

  /**
    *
    * @param {Object} $account object jquery of dom account
    * @returns {void}
    * @private
  */
  var selectAccount = function($account) {
    $account.find(".js-carousel-account-input").prop('checked', true);
    $account.find(".js-carousel-account-input").parent().addClass('checked');
    $account.addClass('dgpCarousel_account-selected');

    $submitBtn.attr('disabled', false);

    $('.js-digiplusFormValidation').addClass('account-selected');
    document.location.href = '#pinpadCode';

    // set input form login hided with the value selected in the caroussel
    var input = $account.find(".js-carousel-account-input");
    loginSelectHided.val(input.attr('value'));

    //console.log(input.attr('value'));
    //console.log(loginSelectHided.val());

  };

  /**
    *
    * @returns {void}
    * @private
  */
  var clickOnAccount = function() {
    $accounts.on("click", function() {
      if (!$(this).hasClass('dgpCarousel_account-disabled')) {
        clearAccounts();
        selectAccount($(this));
      }
    });
  };

  init();
};

function newInstance(elementJquery){
  return new PairingAccountCtrl(elementJquery);
}

module.exports = {
  newInstance
};
